<template>
  <div class="mission">
    <div class="container">
      <div class="mission__inner">
        <h3
          class="mission__suptitle hairline-1 w700 left-to-right"
          v-scrollanimation
        >
          Наша миссия
        </h3>
        <div class="mission__head flex f-space-between" v-scrollanimation>
          <div class="mission__image flex-1">
            <img
              src="@/assets/logo_vertical.svg"
              alt="logo"
              class="mission__logo left-to-right delay-1"
            />
          </div>
          <h2
            class="mission__title headline-2 w700 flex-1 right-to-left delay-1"
          >
            МЫ ВЕДЕМ БИЗНЕС В СООТВЕТСТВИИ С ЦЕННОСТЯМИ:
          </h2>
        </div>
        <div class="mission__grid">
          <div class="empty"></div>
          <div class="mission__item" v-scrollanimation>
            <h2 class="headline-5 w700 bottom-to-top delay-1">01 КЛИЕНТ</h2>
            <p class="body-3 w400 bottom-to-top delay-2">
              Мы строим взаимовыгодное сотрудничество. Нам под силу сложные
              задачи: выведение на рынок нового продукта, первый выход в
              диджитал, комплексные стратегии продвижения.
            </p>
          </div>
          <div class="mission__item" v-scrollanimation>
            <h2 class="headline-5 w700 bottom-to-top delay-1">
              02 ОТВЕТСТВЕННОСТЬ
            </h2>
            <p class="body-3 w400 bottom-to-top delay-2">
              Каждый сотрудник агентства предоставляет услуги высокого качества
              — как внутри команды, так и снаружи. Мы контролируем процесс
              размещения рекламы. Чтобы бизнес клиента был успешным, следим за
              ходом кампаний, оптимизируем размещения, анализируем показатели
              для достижения KPI. Мы постоянно на связи с клиентом —
              консультируем, объясняем, поддерживаем. А также умело
              коммуницируем между собой, быстро решаем вопросы и поддерживаем
              друг друга.
            </p>
          </div>
          <div class="empty"></div>
          <div class="empty"></div>
          <div class="mission__item" v-scrollanimation>
            <h2 class="headline-5 w700 bottom-to-top delay-1">03 РАЗВИТИЕ</h2>
            <p class="body-3 w400 bottom-to-top delay-2">
              У нас комплексный подход к обучению: осваиваем новые технологии в
              digital-рекламе, следим за новостями рынка, повышаем квалификацию,
              развиваем soft-skills и hard-skills, применяем новые инструменты.
              А еще мы учимся благодаря клиентам — узнаем больше о разных
              отраслях и становимся осведомленнее в digital.
            </p>
          </div>
          <div class="mission__item" v-scrollanimation>
            <h2 class="headline-5 w700 bottom-to-top delay-1">
              04 ЭКСПЕРТНОСТЬ
            </h2>
            <p class="body-3 w400 bottom-to-top delay-2">
              У нас десятки лет опыта в digital. Наши специалисты
              сертифицированы Google, мы занимаем призовые места в рейтингах IAB
              и Effie, проводим лекции, учим коллег по рынку. Мы умело
              используем digital в пользу бизнеса.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup></script>

<style lang="scss" scoped>
.flex-1 {
  flex: 1;
}
.mission {
  &__inner {
    padding: 6.25rem 0;
  }

  &__image {
    width: 8rem;
    margin-top: 1.88rem;
    img {
      width: 12rem;
    }
  }

  &__title {
    line-height: 4rem;
  }

  &__suptitle {
    color: var(--grey-3);
  }

  &__head {
    margin-bottom: 4.7rem;
  }

  &__grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-row-gap: 1.25rem;
    grid-column-gap: 0.5rem;
  }

  &__item {
    h2 {
      margin-bottom: 0.63rem;
    }

    &:nth-child(2) {
      border-right: 0.13rem solid var(--secondary-1);
    }
  }
}
@media screen and (max-width: 1336px) {
  .mission {
    &__inner {
      padding: 5rem 0;
      padding-bottom: 2rem;
    }
  }
}
@media screen and (max-width: 768px) {
  .mission {
    &__title {
      font-size: 2.1rem;
      line-height: 3rem;
    }

    &__logo {
      max-width: 7rem;
    }
  }
}

@media screen and (max-width: 576px) {
  .mission {
    &__inner {
      padding: 3rem 0;
    }
  }
}

@media screen and (max-width: 576px) {
  .mission {
    &__title {
      flex: 2;
      font-size: 1.8rem;
    }

    &__grid {
      grid-row-gap: 5rem;
      grid-template-columns: 1fr;
      font-size: 12px;
    }

    .empty {
      display: none;
    }
  }
}
</style>
