<template>
  <div class="team">
    <div class="container">
      <div class="team__inner">
        <div
          class="team__head flex f-space-between align-items-end"
          v-scrollanimation
        >
          <h2 class="team__title headline-2 w700 left-to-right">
            НАША КОМАНДА ОБЪЕДИНЯЕТ ЛУЧШИХ СПЕЦИАЛИСТОВ
          </h2>
          <div class="team__buttons flex right-to-left delay-2">
            <button @click="prev">
              <svg
                width="40"
                height="40"
                viewBox="0 0 40 40"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g opacity="0.7">
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M18.6911 9.35938L8.71494 20.0006L18.6911 30.6418L20.0589 29.3594L12.164 20.9381H30V19.0631H12.164L20.0589 10.6418L18.6911 9.35938Z"
                    fill="white"
                  />
                </g>
              </svg>
            </button>
            <button @click="next">
              <svg
                width="40"
                height="40"
                viewBox="0 0 40 40"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M21.3089 9.35938L31.2851 20.0006L21.3089 30.6418L19.9411 29.3594L27.836 20.9381H10V19.0631H27.836L19.9411 10.6418L21.3089 9.35938Z"
                  fill="#3772FF"
                />
              </svg>
            </button>
          </div>
        </div>
        <Loader v-if="isLoading" />
        <div v-else v-scrollanimation>
          <carousel
            ref="carouselRef"
            :items-to-show="itemShowCount"
            snapAlign="start"
          >
            <slide v-for="(item, index) in teammates" :key="item">
              <div
                class="team__person bottom-to-top"
                :class="`delay-${index * index}`"
              >
                <img :src="item?.photo.medium" alt="teammate" />
                <h3
                  class="team__name headline-6 w700 left-to-right"
                  :class="`delay-${index + 3 * index}`"
                >
                  {{ item?.name }}
                </h3>
                <h4
                  class="team__prof caption-3 w400 left-to-right"
                  :class="`delay-${index + 4 * index}`"
                >
                  {{ item?.position }}
                </h4>
              </div>
            </slide>
          </carousel>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import Loader from "./Loader.vue";
import { ref, computed } from "vue";
import "vue3-carousel/dist/carousel.css";
import { Carousel, Slide } from "vue3-carousel";
import { useStore } from "vuex";

const store = useStore();

const carouselRef = ref(null);

const itemShowCount = ref(4);

const isLoading = ref(true);

store.dispatch("getTeammates", {
  cb: () => {
    isLoading.value = false;
  },
});

const handleResize = () => {
  if (window.innerWidth > 500 && window.innerWidth <= 600) {
    itemShowCount.value = 3.3;
  } else if (window.innerWidth <= 500) {
    itemShowCount.value = 2.2;
  } else {
    itemShowCount.value = 4;
  }
};

if (typeof window != undefined) {
  if (window.innerWidth <= 600) {
    itemShowCount.value = 3.3;
  } else if (window.innerWidth <= 500) {
    itemShowCount.value = 2.2;
  } else {
    itemShowCount.value = 4;
  }
  window.addEventListener("resize", handleResize);
}

const next = () => {
  carouselRef.value.next();
};

const prev = () => {
  carouselRef.value.prev();
};

const teammates = computed(() => store.getters.teammates);
</script>
<style lang="scss" scoped>
.carousel {
  &__next,
  &__prev {
    position: relative;
    left: -30px;
    box-sizing: content-box;
    border: 0.31rem solid var(--secondary-1) !important;
  }
}

.team {
  &__inner {
    padding: 6.25rem 0;
  }

  &__title {
    line-height: 4.31rem;
  }

  &__head {
    margin-bottom: 2.5rem;
  }

  &__buttons {
    button {
      background: none;
      margin-left: 1.25rem;
    }
  }

  &__track {
    gap: 1.88rem;
  }

  &__person {
    width: 100%;
    padding: 0 1rem;
    text-align: left;

    img {
      width: 100%;
      height: 16rem;
      border-radius: 1rem;
      margin-bottom: 1rem;
      pointer-events: none;
    }
  }

  &__name {
    margin-bottom: 0.38rem;
  }
}

@media screen and (max-width: 1336px) {
  .team {
    &__inner {
      padding: 5rem 0;
    }

    &__person {
      img {
        height: 13rem;
      }
    }
  }
}

@media screen and (max-width: 992px) {
  .team {
    &__title {
      font-size: 2rem;
      line-height: 3rem;
      max-width: 70%;
    }
  }
}

@media screen and (max-width: 600px) {
  .team {
    &__inner {
      padding: 3rem 0;
    }

    &__title {
      font-size: 1.6rem;
      max-width: 100%;
    }

    &__buttons {
      display: none;
    }
  }
}

@media screen and (max-width: 425px) {
  .team {
    &__title {
      font-size: 1.4rem;
      line-height: 2rem;
    }
  }
}
</style>
